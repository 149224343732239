<template>
  <v-layout row wrap>
    <v-flex xs12>
      <app-title title="Configuracion" :subtitle="`Solo para la ${zone.name}`"> </app-title>
    </v-flex>
    <v-flex xs12 lg12 xl12>
      <v-card>
        <app-title-card :title="'General'"></app-title-card>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout wrap>
            <v-flex xs12 sm4>
              <v-text-field
                v-model="zone.min"
                flat
                hide-details
                label="Monto Minimo"
                type="number"
                prepend-icon="attach_money"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm4>
              <v-text-field v-model="zone.pass_diseno" flat hide-details label="Contraseña Admin Diseño"></v-text-field>
            </v-flex>
            <v-flex xs12 sm4>
              <v-select
                v-model="zone.payment"
                :items="payments"
                label="Formas de pago"
                multiple
                hide-details
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6>
              <v-combobox
                v-model="zone.sale_day"
                :items="productsAll"
                item-text="name"
                item-value="id"
                label="Oferta del día"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip :selected="data.selected" close small @input="zone.sale_day = ''">
                    <span v-text="shortName(data.item.name)"></span>
                  </v-chip>
                </template>

                <template slot="item" slot-scope="data">
                  <span class="mr-0" v-html="data.item.name"></span>
                  <span v-if="data.item.status" class="caption ml-1 success--text">(Activo)</span>
                  <span v-else class="caption ml-1 error--text">(Inactivo)</span>
                </template>
              </v-combobox>
            </v-flex>
            <v-flex xs12 sm6>
              <v-combobox
                v-model="zone.product_special_id"
                :items="productsAll"
                item-text="name"
                item-value="id"
                label="Producto especial"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip :selected="data.selected" close small @input="zone.product_special_id = ''">
                    <span v-text="shortName(data.item.name)"></span>
                  </v-chip>
                </template>

                <template slot="item" slot-scope="data">
                  <span class="mr-0" v-html="data.item.name"></span>
                  <span v-if="data.item.status" class="caption ml-1 success--text">(Activo)</span>
                  <span v-else class="caption ml-1 error--text">(Inactivo)</span>
                </template>
              </v-combobox>
            </v-flex>

            <v-flex xs12 sm3>
              <v-switch v-model="zone.product_color_active" :label="`Venta Flowerbox`" hide-details></v-switch>
            </v-flex>
            <v-flex xs12 sm3 class="text-xs-right">
              <v-switch v-model="zone.days_status" :label="`Reparto gratis`" hide-details></v-switch>
            </v-flex>
            <v-flex v-if="zone.days_status" xs12 sm3>
              <v-text-field
                v-model="zone.days_free"
                flat
                hide-details
                :label="'Despues de cuantos dias?'"
              ></v-text-field>
            </v-flex>
            <v-flex v-if="zone.usd_value && user.shop.admin" xs12 sm3>
              <v-text-field v-model="zone.usd_value" flat hide-details :label="'Valor dolar'"></v-text-field>
            </v-flex>
            <v-flex v-if="user.shop.admin" xs12 sm6>
              <v-menu
                ref="menu"
                v-model="menudatesSuscription"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="zone.dates_blocked_suscription"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-combobox
                    v-model="zone.dates_blocked_suscription"
                    multiple
                    chips
                    small-chips
                    label="Fechas bloqueadas suscripcion"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  >
                    <template v-slot:selection="{ item, parent, selected }">
                      <v-chip :selected="selected" label small>
                        <span>
                          {{ $moment(item).format('DD-MM-YYYY') }}
                        </span>
                      </v-chip>
                    </template>
                  </v-combobox>
                </template>
                <v-date-picker v-model="zone.dates_blocked_suscription" multiple no-title scrollable locale="es-es">
                  <v-spacer></v-spacer>
                  <v-btn flat color="primary" @click="menudatesSuscription = false">Cancel</v-btn>
                  <v-btn flat color="primary" @click="$refs.menu.save(zone.dates_blocked_suscription)">OK</v-btn>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6>
              <v-combobox
                v-model="zone.categories_retirement"
                multiple
                :items="categoriesAll"
                item-text="name"
                item-value="id"
                label="Categorias para retiro en tienda"
              >
                <template slot="selection" slot-scope="data">
                  <v-chip :selected="data.selected" close small @input="removeCategory(data.item.id)">
                    <span v-text="shortName(data.item.name)"></span>
                  </v-chip>
                </template>

                <template slot="item" slot-scope="data">
                  <span class="mr-0" v-html="data.item.name"></span>
                </template>
              </v-combobox>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-0" color="accent" flat @click="saveGeneral">
            Listo
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import appTitle from '../useful/title.vue'
import appTitleCard from '../useful/titleCard.vue'
import { GET_PRODUCTS_ALL_MIN, MI_STORE, CONFIG } from '../../config'

export default {
  name: 'ZoneStore',
  components: {
    appTitleCard,
    appTitle
  },
  data: () => ({
    categoriesAll: [],
    searchText: null,
    switch1: null,
    e7: null,
    tag: null,
    tags: null,
    packs: null,
    categories: [],
    productsAll: [],
    selectCategories: [],
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    zone: {
      days_free: 0,
      days_status: true,
      min: 0,
      pass_diseno: 0,
      payment: [],
      personalized: 0,
      sale_category_1: 0,
      sale_category_2: 0,
      sale_day: 0,
      usd_value: 0,
      name: '',
      dates_blocked_suscription: [],
      categories_retirement: []
    },
    payments: ['transferencia', 'webpay', 'paypal', 'kiphu', 'servipag', 'payu', 'multicaja'],
    menudatesSuscription: false
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    async getProducts() {
      try {
        const res = await this.$http.get(`${GET_PRODUCTS_ALL_MIN}`)
        this.productsAll = res.data
        this.productsAll.forEach((element, index) => {
          this.productsAll[index].name = `P${element.id} - ${element.name}`
        })
        this.getShop()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getCategories() {
      try {
        const res = await this.$http.get(`${CONFIG}/categories`)
        this.categoriesAll = res.data
        this.getProducts()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getShop() {
      try {
        const res = await this.$http.get(`${MI_STORE}/shop`)
        const zone = res.data
        if (zone.sale_day > 0) {
          zone.sale_day = this._.find(this.productsAll, ['id', zone.sale_day])
        }
        if (zone.product_special_id > 0) {
          zone.product_special_id = this._.find(this.productsAll, ['id', zone.product_special_id])
        }
        if (zone.sale_category_1 > 0) {
          zone.sale_category_1 = this._.find(this.productsAll, ['id', zone.sale_category_1])
        }
        if (zone.sale_category_2 > 0) {
          zone.sale_category_2 = this._.find(this.productsAll, ['id', zone.sale_category_2])
        }

        if (zone.categories_retirement.length > 0) {
          const res_ = this.categoriesAll.filter(function(item) {
            return zone.categories_retirement.indexOf(item.id) != -1
          })
          zone.categories_retirement = res_
        }
        this.zone = zone
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async saveGeneral() {
      try {
        this.zone.categories_retirement = this.zone.categories_retirement.map(e => {
          return e.id
        })
        await this.$http.put(`${MI_STORE}/shop`, {
          zone: this.zone
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado!'
        })
        this.getShop()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    shortName(s) {
      const count = 40
      return s.slice(0, count) + (s.length > count ? '...' : '')
    },
    removeCategory(id) {
      this.zone.categories_retirement = this.zone.categories_retirement.filter(e => e.id !== id)
    }
  }
}
</script>
